input[type=submit] {
    display: block;
    width: 100%;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    border-radius: 10px;
    background-color: $main-color;
    padding: 7px;
    border: none;
    transition: all 300ms ease;
    &:hover {
        background-color: $main-color;
    }
}

.form-group {
    label {
        font-family: Raleway;
        font-size: 16px;
        font-weight: 500;

        width: 100%;
    }
    input:not([type=checkbox]):not([type=radio]), textarea {
        border: 1px solid $line-color;
        //border-bottom: 1px solid $main-color ;
        font-family: $main-font !important;
        font-size: 15px !important;
        font-weight: 400;
        font-style: normal;
        //letter-spacing: 2.61px;
        width: 100%;
        padding: 10px;
        border-radius: 0 !important;
        &:focus {
            outline:none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
        &::placeholder {
            font-size: 15px !important;
            font-weight: 400;
            font-style: normal;
            //letter-spacing: 2.61px;
        }
    }

    .input-group input {
        &:focus {
            outline:none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
    }

}
.checkout-form {
    .form-group.inputs {
        .input-container {
            display: block;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: $line-color;
            }
            &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background-color: $line-color;
                transition: all 300ms ease-in-out;
            }
            label {
                position: absolute;
                top: 10px;
                left: 0;
                transition: all 300ms ease-in-out;
            }
            &.focused {
                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    width: 100% !important;
                    height: 1px;
                    background-color: $main-color !important;
                }
                label {
                    position: absolute;
                    top: -10px;
                    left: 0;
                    font-size: 12px;
                }
            }
            &.filled {
                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    background-color: $line-color;
                }
                label {
                    position: absolute;
                    top: -10px;
                    left: 0;
                    font-size: 12px;
                }
            }


            input {
                border: none;
                padding: 10px 10px 2px 0;
            }
        }
    }
}

.expand-transition {
    transition: all 0.5s ease;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
    height: 0;
    opacity: 0;
}
