// Body
$body-bg: #f8fafc;

// Typography
@import url("https://use.typekit.net/lkm0ktl.css");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Semibold.eot');
    src: url('/fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Graphik-Semibold.woff2') format('woff2'),
    url('/fonts/Graphik-Semibold.woff') format('woff'),
    url('/fonts/Graphik-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Bold.eot');
    src: url('/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Graphik-Bold.woff2') format('woff2'),
    url('/fonts/Graphik-Bold.woff') format('woff'),
    url('/fonts/Graphik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


$font-family-sans-serif: 'Lato', sans-serif;
$font-headers:  'Graphik', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$main-font: "Lato", sans-serif;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$main-color: var(--main-color);
$dark-main-color: #07486d;
$dark-grey: #2d2b2b;
$light-grey: #989998;
$line-color: #cfcfcf;
$block-bg: #f6f6f6;
$alt-color: #f5b54e;
$brown: var(--main-color);
$khaki: var(--main-color);


