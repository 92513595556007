select, .select2-container .select2-selection--single {
    border: 1px solid $gray-300;
    border-bottom: 1px solid $main-color ;
    width: 100%;
    padding: 10px;
    height: 45px;
    .select2-selection__arrow {
        height: 45px;
    }
}
